import React from 'react'
import ElearningLayout from '../Elearning/components/ElearningLayout'
import PlatformCard from '../../components/PlatformCard'

function Certificate() {
  const options = [
    {
      url: 'https://forms.gle/4Gkxe82dZu4qvSGu5',
      title: 'Peppermint Delivery Framework Certification',
      icon: 'fa-solid fa-certificate',
    },
    {
      url: 'https://jxshafuakdr.typeform.com/to/av01UMKw',
      title: 'Cybersecurity certification',
      icon: 'fa-solid fa-certificate',
    },
  ]

  return (
    <>
      <ElearningLayout>
        {options.map((x) => (
          <PlatformCard
            key={x.title}
            url={x.url}
            title={x.title}
            description={x.desc}
            icon={x.icon}
            callBack={x.callBack}
            width="30em"
          />
        ))}
      </ElearningLayout>
    </>
  )
}
export default Certificate
