import React, { useState } from "react";

import {
  FormControl,
  FormHelperText,
  Box,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchema } from "../../constants/schema";
import axios from "axios";

import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

const schema = yup
  .object({
    new_password: validationSchema.new_password,
    confirm_password: validationSchema.confirm_password,
  })
  .required();

function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate and submit form data
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    if (!token) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
        autoHideDuration: 3000,
        onClose: () => {},
      });
      return;
    }
    setLoading(true);
    setSuccess(false);
    axios({
      url: `${process.env.REACT_APP_IAM_URL}/api/v1/auths/resetUserPassword/${token}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        password: data.new_password,
      },
    })
      .then((res) => {
        setSuccess(true);
        reset();
      })
      .catch((err) => {
        setSuccess(false);
        const error =
          err?.response?.data?.error ||
          "Something went wrong, please try again.";
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
          onClose: () => {},
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" color="primary">
          Password reset
        </Typography>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormControl fullWidth variant="outlined" error={errors.new_password} sx={{ my: "20px" }}>
            <InputLabel htmlFor="password">Enter new Password</InputLabel>
            <OutlinedInput
              id="new_password"
              margin="normal"
              required
              fullWidth
              name="new_password"
              label="Enter new Password"
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword1(!showPassword1)}>
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...register("new_password")}
              type={showPassword1 ? "text" : "password"}
            />
            {errors.new_password && (
              <FormHelperText>{errors.new_password?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            error={errors.confirm_password}
          >
            <InputLabel htmlFor="password">Confirm new password</InputLabel>
            <OutlinedInput
              id="confirm_password"
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm new password"
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword2(!showPassword2)}>
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...register("confirm_password")}
              type={showPassword2 ? "text" : "password"}
            />
            {errors.confirm_password && (
              <FormHelperText>
                {errors.confirm_password?.message}
              </FormHelperText>
            )}
          </FormControl>
          {success && (
            <Typography color="primary" fontWeight={300}>
              Your password has been changed. Click <a href="/login">here</a> to
              login.
            </Typography>
          )}
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
