import { createTheme } from "@mui/material/styles";

import Color from "../constants/color";

const theme = createTheme({
  palette: {
    primary: {
      main: Color.primary,
    },
    secondary: {
      main: Color.white,
    },
    lightgray: {
      main: Color.lightgray,
    },
    darkgray: {
      main: Color.darkgray,
    },
    lightPurple: {
      main: Color.lightPurple,
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
