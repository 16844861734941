import React, { useState, useEffect } from 'react'
import PlatformCard from '../components/PlatformCard'
import PpmNav from '../components/PpmNav';
import axios from 'axios';
import Cookies from 'universal-cookie';

function Home() {
  const cookies = new Cookies();
  const [userRoleData, setUserRoleData] = useState(cookies.get("user_role"))

  const platforms = [
    {
      url: process.env.REACT_APP_AL_URL,
      icon: 'fa-sharp fa-solid fa-calendar',
      title: 'Allocation',
      desc: 'Peppermint resource allocation platform.',
      accessibility: userRoleData?.allocation
    },
    {
      url: process.env.REACT_APP_PF_URL,
      icon: 'fa-sharp fa-solid fa-dollar-sign',
      title: 'Profitability',
      desc: 'Peppermint profitability management platform.',
      accessibility: userRoleData?.profitability
    },
    {
      url: process.env.REACT_APP_SK_URL,
      icon: 'fa-sharp fa-solid fa-people-group',
      title: 'Skill Platform',
      desc: 'Peppermint skills management platform.',
      accessibility: userRoleData?.skills
    },
    {
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSea_W4ybwKfix-iEkrXVIxWqXZjPXRyyqMQjJcBh4JCGTNrcg/viewform',
      icon: 'fa-sharp fa-solid fa-shield',
      title: 'Security incident',
      desc: 'Peppermint security incident form.',
      accessibility: userRoleData?.securityIncident
    },
    {
      url: process.env.REACT_APP_MB_URL,
      icon: 'fa-sharp fa-solid fa fa-tachometer',
      title: 'Maturity Barometer',
      desc: 'Peppermint Maturity Barometer platform.',
      accessibility: userRoleData?.maturityBarometer
    },
    {
      url: process.env.REACT_APP_ES_URL,
      icon: 'fa-solid fa-sailboat',
      title: 'Efficient Sale',
      desc: 'Peppermint Efficient Sale System.',
      accessibility: userRoleData?.efficientSales
    },
    {
      url: '/elearning',
      icon: 'fa-solid fa-desktop',
      title: 'E-learning',
      desc: 'Elearning Resources and Certification',
      accessibility: userRoleData?.elearning
    },
    {
      url: process.env.REACT_APP_SP_URL,
      icon: 'fa-solid fa-truck-field',
      title: 'Supplier Platform',
      desc: 'Supplier Platform.',
      accessibility: userRoleData?.freelance
    },
    {
      url: process.env.REACT_APP_QU_URL,
      icon: 'fa-sharp fa-solid fa fa-tachometer',
      title: 'Quality Control',
      desc: 'In Progress...',
      accessibility: userRoleData?.maturityBarometer
    },
  ]
  return (
    <>
      <PpmNav />
      <div className="d-flex flex-wrap justify-content-around" style={{ fontSize: '20px'}}>
        {platforms.map((x) => (
          (x.accessibility && x.accessibility !== "na") && (
            <PlatformCard
              key={`${x.title}`}
              url={x.url}
              title={x.title}
              icon={x.icon}
              description={x.desc}
              accessibility={x.accessibility}
            />
          )
          
        ))}
      </div>
    </>
  )
}
export default Home
