import React, { useEffect } from 'react'
import { BrowserRouter, useNavigate, useRoutes } from 'react-router-dom'

import Home from './pages/Home'
import Elearning from './pages/Elearning'
import ReplayPage from './pages/Elearning/Replays'
import Login from './pages/Login'

import defautTheme from './theme/themes'
import { Alert, ThemeProvider } from '@mui/material'
import Middleware from './components/Middleware'
import Cookies from 'universal-cookie'

import { SnackbarProvider } from "notistack";
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import ChangePassword from './pages/ChangePassword'
import Certificate from './pages/Certificate'

const MainView = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()

  useEffect(() => {
    if(cookies.get('from') && cookies.get('intraToken') === undefined){
      navigate('/login')
    }

    if(cookies.get("alert") && cookies.get("isSignedOut")) {
      localStorage.clear()
      console.log(cookies.get("isSignedOut"))
      cookies.remove('isSignedOut', { domain: process.env.REACT_APP_SUB_DOMAIN })
      cookies.remove('alert', { domain: process.env.REACT_APP_SUB_DOMAIN })
      navigate('/login')
    }
    else if(cookies.get("alert") && cookies.get("isSignedOut") === undefined) {
      alert("You Don't Have Permission To Access" + " " + cookies.get("alert"))
      cookies.remove('alert', { domain: process.env.REACT_APP_SUB_DOMAIN })
    }
  }, [])

  const routes = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
    {
      path: '/change-password',
      element: <ChangePassword />,
    },
    {
      path: '/',
      element: <Home />,
      index: true,
    },
    {
      path: '/elearning',
      children: [
        {
          path: '',
          element: <Elearning />,
        },
      ],
    },
    {
      path: '/replays',
      children: [
        {
          path: '',
          element: <ReplayPage />,
        },
      ],
    },
    {
      path: '/certificate',
      children: [
        {
          path: '',
          element: <Certificate />,
        },
      ],
    },
    { path: '*', element: <div>Not found</div> },
  ])
  return routes
}
function App() {
  return (
    <ThemeProvider theme={defautTheme}>
      <BrowserRouter>
        <SnackbarProvider maxSnack={3}>
          <MainView />
          <Middleware />
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
export default App
