import React from "react";
import { Link } from "react-router-dom";

function ContentCard({ icon, title, accessibility }) {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <i
        className={icon}
        style={{
          verticalAlign: "middle",
          fontSize: "20px",
          paddingRight: "10px",
          opacity: accessibility === "na" ? "0.3" : 1,
        }}
      ></i>
      <h5
        className="card-title"
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          color: accessibility === "na" ? "#E5E5E5" : "cadetblue",
        }}
      >
        {title}
      </h5>
    </div>
  );
}

function PlatformCard({
  url,
  title,
  callBack,
  accessibility,
  description = "",
  icon = "",
  width = "18rem",
  isExternal = true,
}) {
  const handleClick = (event) => {
    if (accessibility === "na") {
      event.preventDefault();
    } else if (isExternal && !callBack) {
      window.open(url, "_blank");
    } else if (callBack) {
      callBack();
    }
  };

  const cardStyle = {
    color: "cadetblue",
    textDecoration: accessibility === "na" ? "none" : "",
    cursor: accessibility === "na" ? "default" : "pointer",
    transition: "box-shadow 0.2s ease-in-out",
  };

  const cardHoverStyle = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  return isExternal ? (
    <div
      className="card mt-2"
      style={{ width: width, ...cardStyle }}
      onClick={handleClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "";
      }}
    >
      <div className="card-body">
        <ContentCard icon={icon} title={title} accessibility={accessibility} />
        {description && (
          <p
            className="card-text"
            style={{ color: accessibility === "na" ? "#E5E5E5" : "black" }}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  ) : (
    <Link
      to={url}
      className="card mt-2"
      style={{ width: width, ...cardStyle }}
      onClick={handleClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "";
      }}
    >
      <div className="card-body">
        <ContentCard icon={icon} title={title} accessibility={accessibility} />
        {description && (
          <p
            className="card-text"
            style={{ color: accessibility === "na" ? "#E5E5E5" : "black" }}
          >
            {description}
          </p>
        )}
      </div>
    </Link>
  );
}

export default PlatformCard;
