import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchema } from "../../constants/schema";
import axios from "axios";
import Cookies from "universal-cookie";

import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const schema = yup
  .object({
    email: validationSchema.required_email,
    password: validationSchema.required,
  })
  .required();

function Login() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(true);
  const [showPassword, setShowPassword] = React.useState(false);

  const expirationDate = new Date('2100-01-01T00:00:00Z');

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate and submit form data
    handleSubmit(onSubmit)();
  };

  const handleSetCookie = (data) => {
    cookies.set("email", data.email, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })
    cookies.set("password", data.password, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })
  };

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_IAM_URL}/api/v1/auths/loginUser`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        localStorage.setItem("accessToken", res.data.token);

        axios({
          url: `${process.env.REACT_APP_IAM_URL}/api/v1/auths/currentLoginUser`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${res.data.token}`
          }
        })
          .then((response) => {
            handleSetCookie(data)
            cookies.set("intraToken", res.data.token, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })

            cookies.remove("isSignedOut", { domain: process.env.REACT_APP_SUB_DOMAIN })

            cookies.set("user_role", response.data.data.platform, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })

            if (cookies.get("from") === "ES") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_ES_URL;
            }
            else if (cookies.get("from") === "AL") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_AL_URL;
            }
            else if (cookies.get("from") === "PF") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_PF_URL;
            }
            else if (cookies.get("from") === "MB") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_MB_URL;
            }
            else if (cookies.get("from") === "SK") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_SK_URL;
            }
            else if (cookies.get("from") === "SP") {
              cookies.remove("from", { domain: process.env.REACT_APP_SUB_DOMAIN })
              window.location.href = process.env.REACT_APP_SP_URL;
            }
            else {
              navigate("/");
            }
          })
          .catch((err) => {
            const error =
              err?.response?.data?.error ||
              "Something went wrong, please try again.";
            enqueueSnackbar(error, {
              variant: "error",
              autoHideDuration: 3000,
              onClose: () => { },
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        const error =
          err?.response?.data?.error ||
          "Something went wrong, please try again.";
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
          onClose: () => {},
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeRememberMe = (event) => {
    if (event.target.checked) {
      setIsRememberMe(true);
      // cookies.set("rememberMe", true, { domain: "localhost" });
      cookies.set("rememberMe", true, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: new Date(2100, 0, 1) });
    } else {
      setIsRememberMe(false);
      // cookies.remove("rememberMe", { domain: "localhost" });
      cookies.remove("rememberMe", { domain: process.env.REACT_APP_SUB_DOMAIN });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" color="primary">
          Welcome to Peppermint
        </Typography>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormControl
            fullWidth
            variant="outlined"
            error={errors.email}
            sx={{ my: "20px" }}
          >
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <OutlinedInput
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
            />
            {errors.email && (
              <FormHelperText>{errors.email?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined" error={errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...register("password")}
              type={showPassword ? "text" : "password"}
            />
            {errors.password && (
              <FormHelperText>{errors.password?.message}</FormHelperText>
            )}
          </FormControl>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <Checkbox
                  checked={isRememberMe}
                  onChange={handleChangeRememberMe}
                />
              }
              label="Remember me"
            />
            <Box />
            <Box
              component="a"
              variant="text"
              sx={{ color: "primary.main" }}
              href="/forgot-password"
            >
              Forgot password?
            </Box>
          </Stack>
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
