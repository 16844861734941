import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const except = ["/login", "/register", "/forgot-password", "/reset-password"];

const Middleware = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const expirationDate = new Date('2100-01-01T00:00:00Z');

  const handleSetCookie = (data) => {
    // cookies.set("token", data.token, { domain: "localhost" }) //Dev
    cookies.set(`intraToken`, data.token, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })

    // cookies.set("refreshToken", data?.refreshToken, { domain: "localhost" }) //Dev
    cookies.set("intraRefreshToken", data.refreshToken, { domain: process.env.REACT_APP_SUB_DOMAIN, expires: expirationDate })
  };

  const handleRenewAccessToken = (refreshToken) => {
    axios({
      url: `${process.env.REACT_APP_IAM_URL}/api/v1/auths/refreshToken`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        refreshToken: refreshToken,
      },
    })
      .then((res) => {
        handleSetCookie(res.data);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoggedIn(false);
        navigate("/login");
      });
  };

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_IAM_URL}/api/v1/auths/currentLoginUser`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("intraToken")}`,
      },
    })
      .then((res) => {
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
        const refreshToken = cookies.get("intraRefreshToken");
        if (refreshToken) {
          handleRenewAccessToken(refreshToken);
        } else {
          if (except.includes(location.pathname)) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
            navigate("/login");
          }
        }
      });
  }, [location]);

  return (
    <>
      {except.includes(location.pathname) || isLoggedIn ? null : (
        <Box
          sx={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            zIndex: 9999,
          }}
        />
      )}
    </>
  );
};

export default Middleware;
