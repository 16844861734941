import * as yup from "yup";

const FIELD_REQUIRED = "This field is required";
const INVALID_EMAIL = "Invalid email";

export const validationSchema = {
  required: yup.string().required(FIELD_REQUIRED),
  email_address: yup.string().email(INVALID_EMAIL),
  required_email: yup.string().email(INVALID_EMAIL).required(FIELD_REQUIRED),
  password: yup.string().required(FIELD_REQUIRED),
  new_password: yup.string().required(FIELD_REQUIRED),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password")], "Password confirmation does not match")
    .required("This field is required"),
};
