import React, { useState } from 'react'
import ElearningLayout from './components/ElearningLayout'
import PlatformCard from '../../components/PlatformCard'
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Toolbar,
} from '@mui/material'
function Elearning() {
  const [openPpt, setOpenPpt] = useState(false)
  const [loading, setLoading] = useState(false)

  function openDialog() {
    setLoading(true)
    setOpenPpt(true)
  }

  const options = [
    {
      url: '/replays',
      title: 'Replays',
      icon: 'fa-solid fa-play',
      desc: 'Replays : Training Replays',
    },
    {
      title: 'Framework Slide',
      icon: 'fa-solid fa-file-powerpoint',
      desc: 'Framework Presentation',
      callBack: () => {
        openDialog()
      },
    },
    {
      url: '/certificate',
      title: 'Certificate',
      icon: 'fa-solid fa-certificate',
      desc: 'Certificate / PepperMint Certification',
    },
  ]

  return (
    <>
      <ElearningLayout>
        {options.map((x) => (
          <PlatformCard
            key={x.title}
            url={x.url}
            title={x.title}
            description={x.desc}
            icon={x.icon}
            callBack={x.callBack}
            width="30em"
          />
        ))}
      </ElearningLayout>

      <Dialog open={openPpt} fullScreen>
        <AppBar sx={{ position: 'relative', backgroundColor: '#1d3b4e' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenPpt(false)}
              aria-label="close"
            >
              x
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading && (
          <Box sx={{ display: 'flex', width: '100%'}}>
            <CircularProgress className='mx-auto my-2'/>
          </Box>
        )}

        <iframe
          title="PPM Framework"
          src="https://onedrive.live.com/embed?cid=82C5763372DD6085&resid=82C5763372DD6085%21110&authkey=ALwa_9IbOH03iPY&em=2"
          width="100%"
          height="100%"
          onLoad={() => setLoading(false)}
          frameBorder="0"
        ></iframe>
      </Dialog>
    </>
  )
}
export default Elearning
