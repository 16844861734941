import React from 'react'
import PpmNav from '../../../components/PpmNav'
import { Container, Stack } from '@mui/material'

function ElearningLayout({children}) {

  return (
    <>
      <PpmNav />
      <Container maxWidth="xl" style={{ margin: 'auto'}}>
        <Stack spacing={2} direction={'row'} className='flex-wrap justify-content-center'>
          {children}
        </Stack>
      </Container>
      
    </>
  )
}
export default ElearningLayout
